import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Blogo from "../Assets/booksplash_logo.png";
import axios from "axios";
import Pagination from "../Pagination";
import Genre from "../Genre";
import Sort from "../Sort";
import { Link, useNavigate } from "react-router-dom";
import Card from '../Rows';
import "./search.css";

export default function Search() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

const [obj, setObj] = useState({});
const [sort, setSort] = useState({ sort: "rating", order: "desc" });
const [filterGenre, setFilterGenre] = useState([]);
const [page, setPage] = useState(1);
const [searchQuery, setSearchQuery] = useState(''); // State to hold search query
const navigate = useNavigate();
const [isMistDropdownOpen, setIsMistDropdownOpen] = useState(false);
  
  const toggleMistDropdown = () => {
    setIsMistDropdownOpen(!isMistDropdownOpen);
  };


// For Search box 
const handleSearch = (e) => {
  e.preventDefault();
  if (searchQuery.trim()) {
    navigate(`/search?search=${searchQuery}`);
  }
};

 
// for newsletter
function Newso() {
    document.getElementById('newsletter').style.display='block';
}

function Oldso() {
    document.getElementById('newsletter').style.display='none';
}

const scrollToCard = () => {
  const cardElement = document.getElementById('book-row');
  if (cardElement) {
    cardElement.scrollIntoView({ behavior: 'smooth' });
  }
};

// Open and close sidebar
function w3_open() {
  document.getElementById('mySidebar').style.display = 'block';
  document.getElementById('myOverlay').style.display = 'block';
}
 
function w3_close() {
  document.getElementById('mySidebar').style.display = 'none';
  document.getElementById('myOverlay').style.display = 'none';
}

  const getQueryFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('search') || '';
  };

  const fetchResults = async (query) => {
    if (!query) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`http://localhost:4000/api/books?search=${query}`);
      const data = await response.json();
      if (data.error) {
        setError('An error occurred while fetching results.');
      } else if (data.books.length === 0) {
        setError(`No results on ${query} found.`);
      } else {
        setResults(data.books);
      }
    } catch (err) {
      setError('An error occurred while fetching results.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryFromUrl = getQueryFromUrl();
    setQuery(queryFromUrl);
    fetchResults(queryFromUrl);
  }, [location.search]);



  return (
  <>
    <head>        
<meta charset="UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
</head>
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto" />
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>

<body class="w3-content" style={{width:"100%"}} />

{/* <!-- Sidebar/menu --> */}

<nav class="w3-sidebar w3-bar-block w3-white w3-collapse w3-top" style={{zIndex: "3", width: "250px"}} id="mySidebar" >
  <div class="w3-container w3-display-container w3-padding-16" style={{margin:0}} >
    <i id="cross" onClick={w3_close}
    class="fa fa-remove w3-hide-large w3-button w3-display-topright"></i>
    <h3 class="w3-wide"><b>pAgeAstrA</b>
    <hr style={{marginTop:"10px",borderWidth:"3.5px"}}/>
    <hr style={{marginTop:"8px",borderWidth:"2.5px"}}/>
    <hr style={{marginTop:"6 px",borderWidth:"2.2px"}}/>
    </h3>
  </div>

  <div class="w3-padding-64 w3-large w3-text-grey" style={{fontWeight: "bold"}}>
    <a href="/" class="w3-bar-item w3-button">Books_All of them</a>
    {/* <a href="#book-row" class="w3-bar-item w3-button">Novels</a>
    <a href="#" class="w3-bar-item w3-button">Comics</a> */}
    <hr style={{height:"-20px",borderWidth:"2px"}}/>
    <Genre
			filterGenre={filterGenre}
			genres={results.genres ? results.genres : []}
		  setFilterGenre={(genre) => setFilterGenre(genre)} scrollToCard={scrollToCard}	/>

     {/* accordion with arrow care changer */}
    <div>
    {/* Accordion with arrow caret changer */}
    <a
      onClick={toggleMistDropdown}
      href="javascript:void(0)"
      className="w3-bar-item w3-button"
    >
      {/* Add the down class conditionally based on the state */}
      <i className={`fas fa-chevron-${isMistDropdownOpen ? "down" : "right"}`}></i> Filter Here
    </a>
    <div  className={`w3-bar-blockA ${isMistDropdownOpen? "w3-show" : ""} w3-padding-large w3-medium`}>
     
      <Sort sort={sort} setSort={(sort) => setSort(sort)} scrollToCard={scrollToCard} />
      {/* <a href="#" class="w3-bar-item w3-button">author</a>
      <a href="#" class="w3-bar-item w3-button"></a>
      <a href="#" class="w3-bar-item w3-button"></a> */}
    </div>
  </div>

    {/* <Link to="" class="w3-bar-item w3-button">Social</Link> */}
    {/* <a href="#" class="w3-bar-item w3-button">Collab/Partners</a> */}
    <Link to="/about" class="w3-bar-item w3-button">About Pageastra</Link>
    <Link to="/bookcop" class="w3-bar-item w3-button">Sir Lator</Link>

  </div>

  <a href="javascript:void(0)" class="w3-bar-item w3-button w3-padding" onClick={Newso} >Newsletter</a> 
</nav>

{/* <!-- Top menu on small screens --> */}

<header class="w3-bar w3-top w3-hide-large w3-black w3-xlarge">
  <div class="w3-bar-item w3-padding-23 w3-wide">
  <img src={Blogo} style={{width:"52px",height:"52px",marginTop:"9px",marginBottom:"6px"}}  />
  </div>
  <a href="javascript:void(0)" class="w3-bar-item w3-button w3-padding-24 w3-right" 
  style={{padding:"20px 15px 25px 20px", marginTop:"0px"}}
  onClick={w3_open}><i class="fa fa-bars"></i></a>
</header>

{/* <!-- Overlay effect when opening sidebar on small screens --> */}
<div class="w3-overlay w3-hide-large" onClick={w3_close} style={{cursor: "pointer"}} title="close side menu" id="myOverlay"></div>

{/* <!-- !PAGE CONTENT! --> */}
<div class="w3-main" style={{marginLeft: "250px"}}>

  {/* <!-- Push down content on small screens --> */}
  <div class="w3-hide-large" style={{marginTop: "83px"}}></div>
  
  {/* <!-- Top header --> */}
  <header class="w3-container w3-xlarge">
    <p class="w3-left">
        <br/>
        <hr style={{marginTop:"18px",borderWidth:"2.5px"}}/><br/>
    </p>
   
      <form onSubmit={handleSearch}>
        <div class="search">
          <p class="w3-right">
          <input className="search"
          type="text"
          placeholder="Search for books..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} 
        />
          <button type="submit">
          <i className="fa fa-search" />
          </button>
      </p>
      </div>
     </form>    
 
    <br/>
    <h1 className="w3-left">Showing  Results for "{query}"</h1><br/>
    {loading && <p>Loading...</p>}
    
      {error && <p className="w3-left"
       style={{float: "left",color:"red"}}>{error}</p>}
  </header>
  </div>
   
     
      {!loading && !error && results.length > 0 && (
          <div id="book-row" class="rowS">

        <Card books={results} /> 
        </div>
        
      )}
      {!loading && !error && results.length === 0 && <p className="w3-left"
       style={{float: "left",color:"red"}}>No books from {query} found !</p> 
    }
    
    <br/>


    {/* footer  */}
    <footer className='footerS' style={{fontSize:"small",backgroundColor:"#d9b8f4",textAlign:'center'}}>
     <div class="w3-row-padding">
       <div class="w3-col">
        <h4>Contact</h4>
        <p>Questions/Queries? Go Right ahead<br/>
        Wish to collaborate or publish your own book<br/>
         Do'not hesitate to reach us</p>
        <form action="https://formsubmit.co/f1c4efcbc2dc0326a61c8eda5674d758" method="POST" target="_blank">
          <p><input class="w3-input" type="text" placeholder="Name" name="Name" required /></p>
          <p><input class="w3-input" type="email" placeholder="Email" name="Email" required /></p>
          <p><input class="w3-input" type="text" placeholder="Subject" name="Subject" required /></p>
          <p><textarea class="w3-input" type="text" placeholder="Message/Query just jot .." name="Message" required /></p>
          <button type="submit" class="w3-button w3-block w3-black" style={{width:"20%"}}>Send</button>
          <br/>
        </form>
      </div>
      
      <div class="w3-col ">
        <h4>Support</h4>  
        <p>Donations heartily only</p>
        <p>No matter how small!</p>
        <button class="w3-button w3-blue">
          <a href="https://paypal.me/donations176?country.x=IN&locale.x=en_GB" 
        className="link">Donate!</a></button>
        {/* <p><a href="">Help</a></p> */}
      </div>

    </div>
  </footer>
  
  <footer id='footerS' class="w3-center w3-black w3-padding-64" style={{backgroundColor:"black"}} >
    <Link className="link" to="" style={{textAlign:"center",color:"whitesmoke",marginBottom:"15px"}}>Good Read!</Link>
            <div clas="w3-xsmall w3-section" style={{color:"whitesmoke",fontSize:"10px"}}> 
            <p style={{marginTop:"26px",marginBottom:"-8px"}}>copyright©
                pageastra.com</p>
            <p style={{}}>All rights reserved</p>     
            </div> 
    </footer>

   
  {/* <div class="w3-black w3-center w3-padding-24" style={{maxWidth:"1200px"}}><a href=""  target="_blank" class="w3-hover-opacity">Reading Folks</a></div> */}

  {/* <!-- End page content --> */}


  


    
    </>
  );
}
